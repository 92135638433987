<template>
<div>
    <div class="row page-title">
        <label v-if="IsEdit">
            <span v-if="IsRejected()">Product Request - Rejected</span>
            <span v-else>Product Request - Review</span>
        </label>
        <label v-else>Create New Product</label>
    </div>
    <div class="form-group page-body mt-4">
        <div class="form-group ml-4 mr-4">
            <div class="col-lg-4 p-0">
                <OmniSelect :disabled="IsEdit == true" class="pt-0" placeholder="Enter or Select Client Code" name="Client Code" :showLabel="true" label="Enter or Select Client Code" v-model="ClientCode" :options="Clients"></OmniSelect>
            </div>

            <div class="form-group mt-4">
                <!-- <b-card v-if="IsClientCodeSelected"> -->
                <b-card>
                    <div class="row" v-if="!IsEdit">
                        <div class="col-lg-7 table-mandatory align-self-center">
                            Press enter to add another row to the table.
                        </div>
                        <div class="col-lg-5 button-add-product text-right">
                            <button v-if="!IsEdit" :disabled="!IsClientCodeSelected" @click="openFileUpload()" class="mr-2 text-uppercase btn custom-button btn-primary">
                                Excel Upload
                            </button>
                        </div>
                    </div>
                    <b-table striped hover :fields="fields" :items="ClientProductList" :current-page="currentPage" :per-page="perPage" show-empty responsive id="product-items" class="table-editable">
                        <template v-slot:empty="scope">
                            <label class="empty-table-text" @paste="pasteDataFromClipboard($event)">Select a client code to load the associated products</label>
                        </template>
                        <template v-slot:table-colgroup="scope">
                            <col v-for="field in scope.fields" :key="field.key" :style="{
                    width: field.label === 'Existing Media' ? '10%' : 'auto',
                  }" />
                        </template>
                        <template v-slot:cell(UDEF1)="data">
                            <b-form-input :maxlength="20" v-model="data.item.UDEF1" placeholder="Enter UDEF1" autocomplete="on"></b-form-input>
                        </template>
                        <template v-slot:cell(UDEF2)="data">
                            <b-form-input :maxlength="20" v-model="data.item.UDEF2" placeholder="Enter UDEF2" autocomplete="on"></b-form-input>
                        </template>
                        <template v-slot:cell(ProductName)="data">
                            <b-form-input :maxlength="20" v-model="data.item.ProductName" placeholder="Enter Product Name" autocomplete="on" list="productnames" :disabled="data.item.ExistingProduct" @blur="
                    DisableEditing(data.index, 'ProductName'),
                      $v.ClientProductList.$each.$iter[
                        GetRowIndex(data.index)
                      ].ProductName.$touch()
                  " @focus="ResetListDirty(data.index, 'ProductName')" v-bind:class="{
                    errorborder:
                      $v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                        .ProductName.$invalid &&
                      $v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                        .ProductName.$dirty,
                    noterrorborder:
                      !$v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                        .ProductName.$invalid,
                  }"></b-form-input>
                            <div class="error-message-format" v-if="
                    !$v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                      .ProductName.required &&
                    $v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                      .ProductName.$dirty
                  ">
                                Required field
                            </div>
                            <div class="error-message-format" v-if="
                    !$v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                      .ProductName.maxLength &&
                    $v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                      .ProductName.$dirty
                  ">
                                Product Name should be
                                {{
                    $v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                      .ProductName.$params.maxLength.max
                  }}
                                characters max.
                            </div>
                        </template>
                        <template v-slot:cell(ProductCode)="data">
                            <b-form-input :maxlength="3" :minlength="2" v-model="data.item.ProductCode" list="productcodes" placeholder="Enter Code" :disabled="data.item.ExistingProduct" @blur="
                    DisableEditing(data.index, 'ProductCode'),
                      $v.ClientProductList.$each.$iter[
                        GetRowIndex(data.index)
                      ].ProductCode.$touch()
                  " @focus="ResetListDirty(data.index, 'ProductCode')" v-bind:class="{
                    errorborder:
                      $v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                        .ProductCode.$invalid &&
                      $v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                        .ProductCode.$dirty,
                    noterrorborder:
                      !$v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                        .ProductCode.$invalid,
                  }"></b-form-input>
                            <div class="error-message-format" v-if="
                    !$v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                      .ProductCode.required &&
                    $v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                      .ProductCode.$dirty
                  ">
                                Required field
                            </div>
                            <div class="error-message-format" v-if="
                    !$v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                      .ProductCode.maxLength &&
                    $v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                      .ProductCode.$dirty
                  ">
                                Product Code should be
                                {{
                    $v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                      .ProductCode.$params.maxLength.max
                  }}
                                characters max.
                            </div>
                            <div class="error-message-format" v-if="
                    !$v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                      .ProductCode.minLength &&
                    $v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                      .ProductCode.$dirty
                  ">
                                Input must be at least
                                {{
                    $v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                      .ProductCode.$params.minLength.min
                  }}
                                characters
                            </div>
                            <div class="error-message-format" v-if="
                    !$v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                      .ProductCode.unique &&
                    $v.ClientProductList.$each.$iter[GetRowIndex(data.index)]
                      .ProductCode.$dirty
                  ">
                                This product code already exists.
                            </div>
                        </template>
                        <template v-slot:cell(Media)="data">
                            <div class="text-center action-cell">
                                <OmniSelect :popupWidth="'200px'" placeholder="Select Media" name="Media" :showLabel="false" label="Select Media" :options="GetNonSelectedMediaList(data.item)" :showSearch="false" :showSelectAll="true" selectAllText="All" unSelectAllText="None" v-model="data.item.SelectedMediaList" />
                            </div>
                        </template>
                        <template v-slot:cell(ExistingMedia)="data">
                            <div class="action-cell existing-media-body">
                                <span v-if="data.item.ExistingProduct == true">
                                    {{ formatExistingMedia(data.item.ProductCode) }}
                                </span>
                                <div v-else class="text-center">—</div>
                            </div>
                        </template>
                        <template v-slot:cell(Actions)="data">
                            <div class="text-center action-cell">
                                <!-- <b-img
              :src="editIcon"
              @click="EditProduct(data.index)"
              class="Custom-Edit"
            /> -->
                                <b-img v-if="!IsEdit" :src="deleteIcon" @click="DeleteProduct(data.index)" class="Custom-Delete" />
                            </div>
                        </template>
                        <template v-slot:cell(ProductBillingAddress)="data">
                            <div v-if="BillingPartyNotTimeOnly">
                                <div v-if="data.item.AddedBillingException">
                                    <b-img :src="editIcon" @click="ShowBillingCommissionException(data.index, false)" class="Custom-Edit" />
                                    <span v-if="
                        data.item.ProductBillingAddress.Country !=
                        CountryUnitedStatesValue
                      " class="exception-body">
                                        <span>
                                            {{ data.item.ProductBillingAddress.AddressLine1 }},{{
                          data.item.ProductBillingAddress.AddressLine2
                        }},{{ data.item.ProductBillingAddress.CountryName }}
                                        </span>
                                    </span>
                                    <span v-else class="exception-body">
                                        <span v-if="TruncatedBillingStreetAddress != null">
                                            {{
                          data.item.ProductBillingAddress
                            .TruncatedBillingStreetAddress
                        }}, {{ data.item.ProductBillingAddress.City }},{{
                          data.item.ProductBillingAddress.StateName
                        }},{{ data.item.ProductBillingAddress.ZipCode }}
                                        </span>
                                        <span v-else>
                                            {{
                          data.item.ProductBillingAddress.BillingStreetAddress
                        }}, {{ data.item.ProductBillingAddress.City }},{{
                          data.item.ProductBillingAddress.StateName
                        }},{{ data.item.ProductBillingAddress.ZipCode }}
                                        </span>
                                    </span>
                                </div>
                                <div v-else>
                                    <button class="btn btn-link" @click="ShowBillingCommissionException(data.index, false)">
                                        Add
                                    </button>
                                </div>
                            </div>
                            <div v-else class="text-center">-</div>
                        </template>

                        <template v-slot:cell(CommissionInfo)="data">
                            <div v-if="IsNotAxTimeOnly(data.item)">
                                <!-- <div v-if="BillingPartyNotTimeOnly"> -->

                                <div v-if="data.item.AddedCommissionException">
                                    <b-img :src="editIcon" @click="ShowBillingCommissionException(data.index, true)" class="Custom-Edit" />&nbsp;
                                    <span class="text-left" v-if="
                        data.item.CommissionInfo.CommissionBasis == 'Net' ||
                        data.item.CommissionInfo.CommissionBasis == 'Gross'
                      ">
                                        <span>{{ data.item.CommissionInfo.CommissionBasis }}
                                            {{ data.item.CommissionInfo.CommissionPercentage }}%,
                                            Media :
                                            {{
                          formatMediaTypesForGrid(data.index, data.item)
                        }}</span>
                                    </span>
                                    <span class="text-center" v-if="data.item.CommissionInfo.FeeOnly">
                                        <span v-if="data.item.CommissionInfo.FeeOnly">Fee Only, Media :
                                            {{
                          formatMediaTypesForGrid(data.index, data.item)
                        }}</span>
                                    </span>
                                    <span class="text-center" v-if="
                        data.item.CommissionInfo.TimeOnly &&
                        data.item.CommissionInfo.TimeOnly == 'true'
                      ">
                                        <span>
                                            Time Only, Media :
                                            {{ formatMediaTypesForGrid(data.index, data.item) }}
                                        </span>
                                    </span>
                                    <div style="margin-top:5px;">
                                        <upload-file-holder :Files="data.item.CommissionInfo.Files" :IsDownloadable="false"></upload-file-holder>
                                    </div>
                                </div>
                                <div v-else>
                                    <button class="btn btn-link" @click="ShowBillingCommissionException(data.index, true)">
                                        Add
                                    </button>
                                </div>
                            </div>
                            <div v-else class="text-center">-</div>
                        </template>
                        <template v-slot:cell(ProductExceptions)="data">
                            <div v-if="
                    data.item.AddedBillingException ||
                    data.item.AddedCommissionException
                  " class="exception-circle">
                                <span>
                                    <button class="btn btn-link custom-link-button" :id="'billing-exceptions-info' + data.index">
                                        <span class="circle-data">{{
                        (data.item.AddedBillingException ? 1 : 0) +
                          (data.item.CommissionInfo.CommissionBasis == "Net"
                            ? 1
                            : 0) +
                          (data.item.CommissionInfo.CommissionBasis == "Gross"
                            ? 1
                            : 0) +
                          (data.item.CommissionInfo.FeeOnly ? 1 : 0) >
                        1
                          ? 2
                          : 1
                      }}</span>
                                    </button>
                                    <b-popover triggers="hover" placement="top" display="inline" :target="'billing-exceptions-info' + data.index" variant="primary">
                                        <div class="text-left" v-if="
                          data.item.CommissionInfo.CommissionBasis == 'Net' ||
                          data.item.CommissionInfo.CommissionBasis == 'Gross'
                        ">
                                            <div class="exception-title">Commission Basis</div>
                                            <div>
                                                {{ data.item.CommissionInfo.CommissionBasis }}
                                            </div>
                                            <div>
                                                {{ data.item.CommissionInfo.CommissionPercentage }}%
                                            </div>
                                        </div>
                                        <div class="text-center text-white" v-if="data.item.CommissionInfo.FeeOnly">
                                            <div v-if="data.item.CommissionInfo.FeeOnly">
                                                Fee Only
                                            </div>
                                        </div>
                                        <div class="text-center text-white" v-if="data.item.CommissionInfo.TimeOnly">
                                            <div v-if="data.item.CommissionInfo.TimeOnly == 'true'">
                                                Time Only
                                            </div>
                                        </div>
                                        <div class="text-left" v-if="data.item.AddedBillingException">
                                            <div class="exception-title">Billing Address</div>
                                            <div v-if="
                            data.item.ProductBillingAddress.Country !=
                            CountryUnitedStatesValue
                          " class="text-white exception-body">
                                                <div>
                                                    {{ data.item.ProductBillingAddress.AddressLine1 }}
                                                </div>
                                                <div>
                                                    {{ data.item.ProductBillingAddress.AddressLine2 }}
                                                </div>
                                            </div>
                                            <div v-else class="text-white exception-body">
                                                <div>
                                                    {{
                              data.item.ProductBillingAddress
                                .BillingStreetAddress
                            }}
                                                </div>
                                                <div>
                                                    {{ data.item.ProductBillingAddress.City }},{{
                              data.item.ProductBillingAddress.StateName
                            }},{{ data.item.ProductBillingAddress.ZipCode }}
                                                </div>
                                            </div>
                                        </div>
                                    </b-popover>
                                </span>
                            </div>
                            <div v-else>__</div>
                        </template>
                        <template slot="custom-foot">
                            <b-tr>
                                <b-td colspan="2">
                                    <button v-if="IsClientCodeSelected && !IsEdit" class="btn btn-link add-product-row text-left" @click="AddAnotherProduct()">
                                        <img :src="addIcon" alt="add" /> Add another row
                                    </button>
                                </b-td>
                                <b-td colspan="3" v-if="IsPaginationRequired" style="padding-top: 14px !important">
                                    <Pagination style="float: right" aria-controls="open-items" v-model="currentPage" :total-rows="TotalRows" :per-page="perPage" first-number last-number />
                                </b-td>
                            </b-tr>
                        </template>
                    </b-table>
                    <b-modal no-enforce-focus v-model="ShowAddBillingCommissionException" :no-close-on-backdrop="true" scrollable title="Add Product">
                        <template v-slot:modal-header="{ close }">
                            <h5 class="modal-title">Add Billing Exception</h5>
                            <button class="close" @click="CancelAddException()"></button>
                        </template>
                        <div class="d-block row col-lg-10 offset-lg-1 primary-content"></div>
                        <b-card class="secondary-card ml-5 mr-5" v-if="ProductDetails.AddedBillingException == true">
                            <span class="exception-close">
                                <b-img :src="closeIcon" @click="DeleteProductBillingExceptionSection()" class="Custom-Delete delete-exception" title="Delete billing address exception">
                                </b-img>
                            </span>
                            <div class="col-lg-12">
                                <div v-if="!IsCurrentAXTimeOnly">
                                    <OmniSelect placeholder="Select Media" name="Media" :showLabel="true" label="Select Media" :options="ProductSelectedMedia" :showSearch="false" :showSelectAll="true" selectAllText="All" unSelectAllText="None" v-model="
                        ProductDetails.ProductBillingAddress.SelectedMediaList
                      " />
                                </div>

                                <section class="mt-4">
                                    <p class="address-exception-title">Address Exception</p>
                                    <b-form-group cols="12" id="product-modal-bill" label="Product Bill to Name" label-for="bill" :required="false">
                                        <b-form-input v-model="
                          ProductDetails.ProductBillingAddress.BillToName
                        " placeholder="Product Bill to Name" :maxlength="
                          $v.ProductDetails.ProductBillingAddress.BillToName
                            .$params.maxLength.max
                        " id="bill"></b-form-input>
                                    </b-form-group>
                                    <div class="form-group custom-dropdown">
                                        <OmniSelect placeholder="Select Country" id="country" name="Country" :showLabel="true" label="Select Country" v-model="ProductDetails.ProductBillingAddress.Country" :options="CountryList"></OmniSelect>
                                    </div>
                                    <div class="form-group" v-if="
                        ProductDetails.ProductBillingAddress.Country == null
                      ">
                                        <p class="
                          align-self-center
                          empty-table-text
                          unselected-default-text
                        ">
                                            Select a country to populate the address form
                                        </p>
                                    </div>
                                    <div class="pt-2">
                                        <b-form-group cols="12" v-if="IsCountryUSA" id="product-modal-Address" label="Client Billing Street Address" label-for="street-address">
                                            <b-form-input :maxlength="26" class="" autocomplete="off" id="street-address" v-model="
                            ProductDetails.ProductBillingAddress
                              .BillingStreetAddress
                          " placeholder="Enter Client Billing Street Address"></b-form-input>
                                        </b-form-group>
                                        <b-form-group cols="12" v-if="IsCountryUSA == false" id="product-modal-Address1" label="Client Billing Street Address" label-for="address1">
                                            <b-form-input v-model="
                            ProductDetails.ProductBillingAddress.AddressLine1
                          " v-if="IsCountryUSA == false" placeholder="Address Line 1" :maxlength="26" autocomplete="off" id="address1"></b-form-input>
                                        </b-form-group>
                                        <b-form-group cols="12" v-if="IsCountryUSA == false" id="product-modal-Address2" label-for="address2">
                                            <b-form-input v-model="
                            ProductDetails.ProductBillingAddress.AddressLine2
                          " autocomplete="off" class="mt-3" v-if="IsCountryUSA == false" placeholder="Address Line 2" :maxlength="26" id="address2"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="form-group custom-dropdown-bottom" v-if="IsCountryUSA">
                                        <b-form-group label-for="city" label="City">
                                            <b-form-input autocomplete="off" v-model="ProductDetails.ProductBillingAddress.City" id="city" placeholder="Enter City" :maxlength="
                            $v.ProductDetails.ProductBillingAddress.City.$params
                              .maxLength.max
                          "></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="row" v-if="IsCountryUSA">
                                        <div class="col-lg-6 custom-dropdown">
                                            <OmniSelect class="pt-0" placeholder="Select State" name="States" :showLabel="true" :showSearch="true" label="Select State" v-model="ProductDetails.ProductBillingAddress.State" :options="States"></OmniSelect>
                                        </div>
                                        <div class="col-lg-6 ml-0 mt-2">
                                            <b-form-group label-for="zip-code" v-if="IsCountryUSA" label="Zip Code">
                                                <b-form-input @blur="
                              $v.ProductDetails.ProductBillingAddress.ZipCode.$touch()
                            " @focus="ResetZipCodeDirty()" autocomplete="off" :class="{
                              'validation-status-error':
                                $v.ProductDetails.ProductBillingAddress.ZipCode
                                  .$invalid &&
                                $v.ProductDetails.ProductBillingAddress.ZipCode
                                  .$dirty,
                            }" v-model="
                              ProductDetails.ProductBillingAddress.ZipCode
                            " id="zip-code" placeholder="Enter Zip Code" :maxlength="
                              $v.ProductDetails.ProductBillingAddress.ZipCode
                                .$params.maxLength.max
                            "></b-form-input>

                                                <div v-show="
                              !$v.ProductDetails.ProductBillingAddress.ZipCode
                                .minLength &&
                              $v.ProductDetails.ProductBillingAddress.ZipCode
                                .$dirty
                            " class="error-message-format">
                                                    Input must be 5 characters
                                                </div>
                                                <div v-show="
                              !$v.ProductDetails.ProductBillingAddress.ZipCode
                                .numeric &&
                              $v.ProductDetails.ProductBillingAddress.ZipCode
                                .$dirty
                            " class="error-message-format">
                                                    This field can only contain numerical values
                                                </div>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <b-form-group cols="12" id="product-modal-attn" label="Attn" label-for="attn" :required="false">
                                        <b-form-input v-model="ProductDetails.ProductBillingAddress.Attn" placeholder="Attn" :maxlength="
                          $v.ProductDetails.ProductBillingAddress.Attn.$params
                            .maxLength.max
                        " id="attn"></b-form-input>
                                    </b-form-group>
                                    <br />
                                    <br />

                                    <div v-if="showtrucatedbilling">
                                        <!-- <span>Billing Address Changes</span> -->
                                        <span class="SUGGESTED-CLIENT-NAM">
                                            SUGGESTED STREET ADDRESS
                                        </span>
                                        <br />
                                        <span class="Due-to-character-res">
                                            Due to character restrictions, we suggest the use of the
                                            following address:
                                        </span>
                                        <br />
                                        <b-form-group cols="12" v-if="IsCountryUSA" id="product-modal-Address" label="Client Billing Street Address" label-for="street-address" style="margin-top: 20px">
                                            <b-form-input class="" autocomplete="off" id="street-address" v-model="
                            ProductDetails.ProductBillingAddress
                              .TruncatedBillingStreetAddress
                          " placeholder="Enter Client Billing Street Address" :maxlength="26"></b-form-input>
                                        </b-form-group>
                                        <div class="form-group custom-dropdown-bottom" v-if="IsCountryUSA">
                                            <b-form-group label-for="city" label="City">
                                                <b-form-input autocomplete="off" v-model="ProductDetails.ProductBillingAddress.City" id="city" placeholder="Enter City" :maxlength="
                              $v.ProductDetails.ProductBillingAddress.City
                                .$params.maxLength.max
                            "></b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="row" v-if="IsCountryUSA">
                                            <div class="col-lg-6 custom-dropdown">
                                                <OmniSelect class="pt-0" placeholder="Select State" name="States" :showLabel="true" :showSearch="true" label="Select State" v-model="ProductDetails.ProductBillingAddress.State" :options="States"></OmniSelect>
                                            </div>
                                            <div class="col-lg-6 ml-0 mt-2">
                                                <b-form-group label-for="zip-code" v-if="IsCountryUSA" label="Zip Code">
                                                    <b-form-input @change="
                                $v.ProductDetails.ProductBillingAddress.ZipCode.$touch()
                              " autocomplete="off" :class="{
                                'validation-status-error':
                                  $v.ProductDetails.ProductBillingAddress
                                    .ZipCode.$error,
                              }" v-model="
                                ProductDetails.ProductBillingAddress.ZipCode
                              " id="zip-code" placeholder="Enter Zip Code" :maxlength="
                                $v.ProductDetails.ProductBillingAddress.ZipCode
                                  .$params.maxLength.max
                              "></b-form-input>
                                                    <p v-show="
                                !$v.ProductDetails.ProductBillingAddress.ZipCode
                                  .minLength
                              " class="validation-status-error-message">
                                                        Minimum 5 characters required.
                                                    </p>
                                                    <p v-show="
                                !$v.ProductDetails.ProductBillingAddress.ZipCode
                                  .numeric
                              " class="validation-status-error-message">
                                                        Please enter number between 0 to 9.
                                                    </p>
                                                </b-form-group>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </b-card>
                        <div class="ml-5 mr-5">
                            <button class="btn btn-link add-product-row text-left" v-if="ProductDetails.AddedBillingException != true" @click="AddProductBillingExceptionSection()">
                                <img :src="addIcon" alt="add" /> Add Billing Exception
                            </button>
                            <button class="btn btn-link add-product-row text-left" v-if="
                    ProductDetails.AddedCommissionException != true &&
                    IsNotAxTimeOnly(ProductDetails)
                  " @click="AddProductCommissionExceptionSection()">
                                <img :src="addIcon" alt="add" /> Add Commission Exception
                            </button>
                        </div>
                        <b-card class="secondary-card commission-exception ml-5 mr-5" v-if="ProductDetails.AddedCommissionException == true">
                            <!-- <div class="form-group custom-dropdown">

                </div> -->

                            <b-img :src="closeIcon" @click="DeleteProductCommissionExceptionSection()" class="Custom-Delete delete-exception" title="Delete commission exception">
                            </b-img>
                            <div>
                                <OmniSelect :disabled="AgencyFound" placeholder="Select Agency" name="Agency" :showLabel="true" label="Select Agency" v-model="ProductDetails.Agency" :options="AgencyList"></OmniSelect>
                                <OmniSelect placeholder="Select Media" name="Media" :showLabel="true" label="Select Media" :options="ProductSelectedMedia" :showSearch="false" :showSelectAll="true" selectAllText="All" unSelectAllText="None" v-model="ProductDetails.CommissionInfo.SelectedMediaList" />
                            </div>

                            <section class="mt-4">
                                <p class="commission-exception-title">Commission</p>
                                <b-row class="ml-1 mr-1 mt-2">
                                    <b-form-group class="timeonly-text" label="Is this request Time Only ?">
                                        <b-form-radio-group id="time-only" v-model="ProductDetails.CommissionInfo.TimeOnly" name="time-only">
                                            <b-form-radio value="true">Yes</b-form-radio>
                                            <b-form-radio value="false">No</b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-row>
                                <b-row class="ml-1 mr-1 mt-2">
                                    <b-form-group label="Please select if this client will be billed as a fee or on a commission basis. If commission basis, please select Net or Gross:" v-if="ProductDetails.CommissionInfo.TimeOnly != 'true'">
                                        <b-form-radio class="inline-custom-radio-commission" v-model="ProductDetails.CommissionInfo.FeeOnly" name="CommissionBasis-modal" value="Yes">Fee Only</b-form-radio>
                                        <b-form-radio class="inline-custom-radio-commission" v-model="ProductDetails.CommissionInfo.CommissionBasis" name="CommissionBasis-modal" value="Net">Net</b-form-radio>
                                        <b-form-radio class="inline-custom-radio-commission" v-model="ProductDetails.CommissionInfo.CommissionBasis" name="CommissionBasis-modal" value="Gross">Gross</b-form-radio>
                                    </b-form-group>
                                </b-row>
                                <div class="mt-2 ml-1 mr-1" v-if="
                      ProductDetails.CommissionInfo.TimeOnly != 'true' &&
                      (ProductDetails.CommissionInfo.CommissionBasis == 'Net' ||
                        ProductDetails.CommissionInfo.CommissionBasis ==
                          'Gross')
                    ">
                                    <b-form-group id="product-modal-commission-percentage" label="Commission %" label-for="commision-percentage">
                                        <b-form-input autocomplete="off" id="commision-percentage" v-numericWithDecimal v-model="
                          ProductDetails.CommissionInfo.CommissionPercentage
                        " placeholder="Enter Commission %" :class="{
                          'validation-status-error':
                            !$v.ProductDetails.CommissionInfo
                              .CommissionPercentage.decimal &&
                            $v.ProductDetails.CommissionInfo
                              .CommissionPercentage.$dirty,
                        }" @blur="
                          $v.ProductDetails.CommissionInfo.CommissionPercentage.$touch()
                        " @focus="ResetCommissionDirty()"></b-form-input>
                                        <div v-show="
                          !$v.ProductDetails.CommissionInfo.CommissionPercentage
                            .decimal &&
                          $v.ProductDetails.CommissionInfo.CommissionPercentage
                            .$dirty
                        " class="error-message-format">
                                            Please enter valid decimal number. If .(dot) is entered
                                            add at least one number after that.
                                        </div>
                                    </b-form-group>
                                </div>
                                <div class="mt-2 ml-1 mr-1">
                                    <label class="excel-upload-label">Commission Support</label>
                                    <vue-dropzone :duplicateCheck="true" v-on:vdropzone-mounted="mountedCommissionSupport" v-on:vdropzone-file-added="UploadedToDropzone" v-on:vdropzone-removed-file="RemovedFromDropzone" ref="commissionSupportDropzone" id="commissionSupportDropzone" :useCustomSlot="true" class="customdropzone" :include-styling="false" :options="dropzoneOptionsCommission">
                                        <div class="dropzone-custom-content">
                                            <p class="drag-and-drop-file-h">
                                                <b-img :src="closeFilterIcon" style="
                              margin-top: -7px !important;
                              font-family: 'Open Sans';
                            " />
                                                Drag and drop file here or
                                                <span class="draganddropspan"> browse </span>
                                            </p>
                                        </div>
                                    </vue-dropzone>
                                </div>
                                <!-- <div
                  class="panel-list media-dropdowns ml-1 mr-1"
                  v-if="ProductDetails.CommissionInfo.TimeOnly != 'true'"
                >
                  <OmniSelect
                    :options="NETList"
                    v-model="SelectedNETList"
                    name="NET"
                    v-if="NETList.length > 0"
                    placeholder="Select Net Media"
                    :showSearch="true"
                    :showSelectAll="true"
                    selectAllText="All"
                    unSelectAllText="None"
                    multiple="true"
                  />
                  <OmniSelect
                    :options="SPOTList"
                    v-model="SelectedSPOTList"
                    name="SPOT"
                    v-if="SPOTList.length > 0"
                    placeholder="Select Spot Media"
                    :showSearch="true"
                    :showSelectAll="true"
                    selectAllText="All"
                    unSelectAllText="None"
                    multiple="true"
                  />
                  <OmniSelect
                    :options="PRINTList"
                    v-model="SelectedPRINTList"
                    name="PRINT"
                    v-if="PRINTList.length > 0"
                    placeholder="Select Print Media"
                    :showSearch="true"
                    :showSelectAll="true"
                    selectAllText="All"
                    unSelectAllText="None"
                    multiple="true"
                  />

                </div> -->
                            </section>
                        </b-card>
                        <template v-slot:modal-footer="{ ok, cancel }">
                            <div class="text-right">
                                <button class="btn btn-link text-uppercase" @click="CancelAddException()">
                                    Cancel
                                </button>
                                <button :disabled="$v.ProductDetails.$invalid || !SelectedMedia" class="text-uppercase btn custom-button btn-primary" @click="AddProductBillingCommissionException()">
                                    Add
                                </button>
                                <!-- <button
              v-if="!IsAddModal"
              :disabled="$v.$invalid || !SelectedMedia"
              class="text-uppercase btn custom-button btn-primary"
              @click="SaveProductException()"
            >
              Save
            </button> -->
                            </div>
                        </template>
                    </b-modal>
                    <b-modal v-model="ShowDelete" hide-footer :no-close-on-backdrop="true">
                        <div>
                            <p>
                                This action will permanently delete this product. This cannot
                                be undone.
                            </p>
                        </div>
                        <template v-slot:modal-header="{ close }">
                            <h5 class="modal-title">{{ DeleteProductTitle }}</h5>
                            <button class="close" @click="CancelDelete()"></button>
                        </template>

                        <div class="text-right">
                            <button class="btn btn-link text-uppercase" @click="CancelDelete()">
                                Cancel
                            </button>
                            <button class="text-uppercase btn custom-button btn-primary" @click="DeleteConfirm()">
                                Delete
                            </button>
                        </div>
                    </b-modal>
                    <b-modal v-model="ShowExcelUpload" :no-close-on-backdrop="true">
                        <template v-slot:modal-header="{ close }">
                            <h5 class="modal-title">Excel Upload</h5>
                            <button class="close" @click="CancelExcelUpload()"></button>
                        </template>
                        <div>
                            <b-card class="products-upload-card">
                                <section class="excel-file-upload-steps">
                                    <label>How to upload excel file for product table:</label>
                                    <ol>
                                        <li>
                                            <!-- <xlsx-workbook>
                    <xlsx-sheet
                      :collection="sheet.data"
                      v-for="sheet in sheets"
                      :key="sheet.name"
                      :sheet-name="sheet.name"
                      :comments="sheet.comments"
                    />
                    <xlsx-download :filename="'FinancialSystemsProducts.xlsx'" :options="downloadOptions">
                      <button>Download this template</button>
                    </xlsx-download>
                  </xlsx-workbook> -->
                                            <a @click="downloadProductsUploadTemplate()">Download this template</a>
                                        </li>
                                        <li>Add your data to the template file</li>
                                        <li>Upload it below</li>
                                    </ol>
                                </section>
                            </b-card>
                            <div class="mt-4">
                                <div class="col-lg-10 offset-lg-1">
                                    <label class="excel-upload-label">Excel Upload</label>
                                    <vue-dropzone :duplicateCheck="true" ref="excelUploadDropzone" id="dropzone" :useCustomSlot="true" class="customdropzone" :include-styling="false" :options="dropzoneOptions">
                                        <div class="dropzone-custom-content">
                                            <p class="drag-and-drop-file-h">
                                                <b-img :src="closeFilterIcon" style="
                              margin-top: -7px !important;
                              font-family: 'Open Sans';
                            " />
                                                Drag and drop file here or
                                                <span class="draganddropspan"> browse </span>
                                            </p>
                                        </div>
                                    </vue-dropzone>
                                </div>
                            </div>
                        </div>
                        <template v-slot:modal-footer="{ ok, cancel }">
                            <div class="text-right">
                                <button class="btn btn-link text-uppercase" @click="CancelExcelUpload()">
                                    Cancel
                                </button>
                                <button class="text-uppercase btn custom-button btn-primary" @click="AddProductsFromExcel()">
                                    Add
                                </button>
                            </div>
                        </template>
                    </b-modal>
                    <datalist class="omg__dropdown-options bg-white box-shadow" id="productnames">
                        <option class="omg__option" v-for="name in ProductsFromServer" :key="name.value" :value="name.label"></option>
                    </datalist>
                    <!-- <OmniSelect id="productnames"   :showLabel="false" :options="ProductsFromServer"></OmniSelect> -->
                    <datalist id="productcodes">
                        <option v-for="name in ProductsFromServer" :key="name.value" :value="name.value"></option>
                    </datalist>
                </b-card>
            </div>
        </div>
    </div>
    <div class="form-group pt-30 text-right">
        <button class="btn custom-button btn-link" @click="Cancel()">
            Cancel
        </button>

        <button v-if="IsEdit && IsRejected()" class="btn custom-button btn-primary" :disabled="!IsFormValid || !IsClientCodeSelected || !IsRejected()" @click="ReSubmit()">
            RE-Submit
        </button>
        <button v-if="!IsEdit" class="btn custom-button btn-primary" :disabled="!IsFormValid || !IsClientCodeSelected" @click="Submit()">
            Submit
        </button>
    </div>
</div>
</template>

<script>
import {
    requiredIf,
    maxLength,
    minLength,
    required,
    numeric,
} from "vuelidate/lib/validators";
import store from "@/store";
import {
    NETMedia,
    PRINTMedia,
    SPOTMedia
} from "../../utilities/MediaList";
import readXlsxFile from "read-excel-file";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import service from "../../services/Dropdowns";
import UploadFileHolder from "@/components/Common/UploadFileHolder";

export default {
    components: {
        vueDropzone: vue2Dropzone,
        UploadFileHolder: UploadFileHolder,
    },
    validations: {
        ClientCode: {
            required,
        },
        ClientProductList: {
            required,
            minLength: minLength(1),
            $each: {
                ProductName: {
                    required: requiredIf(function () {
                        return this.ShowAddBillingCommissionException != true;
                    }),
                    maxLength: maxLength(20),
                },
                ProductCode: {
                    required: requiredIf(function () {
                        return this.ShowAddBillingCommissionException != true;
                    }),
                    maxLength: maxLength(3),
                    minLength: minLength(2),
                    unique(value) {
                        return (
                            !this.ClientProductList ||
                            value == null ||
                            value.length == 0 ||
                            this.ClientProductList.filter((val) => val.ProductCode == value)
                            .length < 2
                        );
                    },
                },
                SelectedMediaList: {
                    minLength: minLength(1),
                    required,
                    // : requiredIf(function (model) {
                    //     return (
                    //         model.ExistingProduct == true
                    //     );
                    // }),
                },
            },
        },
        ProductDetails: {
            Agency: {
                required: requiredIf(function () {
                    return (
                        this.ShowAddBillingCommissionException == true &&
                        this.ProductDetails.AddedCommissionException
                    );
                }),
            },
            CommissionInfo: {
                FeeOnly: {
                    required: requiredIf(function (model) {
                        return (
                            this.ShowAddBillingCommissionException == true &&
                            this.ProductDetails.AddedCommissionException &&
                            model.CommissionBasis == null &&
                            model.TimeOnly != "true"
                        );
                    }),
                },
                CommissionBasis: {
                    required: requiredIf(function (model) {
                        return (
                            this.ShowAddBillingCommissionException &&
                            this.ProductDetails.AddedCommissionException &&
                            model.FeeOnly == null &&
                            model.TimeOnly != "true"
                        );
                    }),
                },
                CommissionPercentage: {
                    decimal(commission, model) {
                        return this.ShowAddBillingCommissionException &&
                            this.ProductDetails.AddedCommissionException &&
                            (model.CommissionBasis == "Net" ||
                                model.CommissionBasis == "Gross") ?
                            /^([0-9]*|[0-9]*.[0-9][0-9]*)$/.test(commission) :
                            true;
                    },
                    required: requiredIf(function (model) {
                        return (
                            this.ShowAddBillingCommissionException &&
                            this.ProductDetails.AddedCommissionException &&
                            (model.CommissionBasis == "Net" ||
                                model.CommissionBasis == "Gross")
                        );
                    }),
                },
                Files: {
                    minLength: minLength(1),
                    required: requiredIf(function () {
                        return (
                            this.ShowAddBillingCommissionException &&
                            this.ProductDetails.AddedCommissionException
                        );
                    }),
                },
            },
            ProductBillingAddress: {
                TruncatedProductName: "",
                BillToName: {
                    maxLength: maxLength(20),
                    required: requiredIf(function () {
                        return (
                            this.ShowAddBillingCommissionException &&
                            this.ProductDetails.AddedBillingException
                        );
                    }),
                },
                Attn: {
                    maxLength: maxLength(10),
                },
                Country: {
                    required: requiredIf(function () {
                        return (
                            this.ShowAddBillingCommissionException &&
                            this.ProductDetails.AddedBillingException
                        );
                    }),
                },
                AddressLine1: {
                    required: requiredIf(function (model) {
                        return (
                            this.ShowAddBillingCommissionException &&
                            this.ProductDetails.AddedBillingException &&
                            model.Country != this.CountryUnitedStatesValue
                        );
                    }),
                },
                AddressLine2: {
                    required: requiredIf(function (model) {
                        return (
                            this.ShowAddBillingCommissionException &&
                            this.ProductDetails.AddedBillingException &&
                            model.Country != this.CountryUnitedStatesValue
                        );
                    }),
                },
                City: {
                    required: requiredIf(function (model) {
                        return (
                            this.ShowAddBillingCommissionException &&
                            this.ProductDetails.AddedBillingException &&
                            model.Country == this.CountryUnitedStatesValue
                        );
                    }),
                    maxLength: maxLength(30),
                },
                ZipCode: {
                    required: requiredIf(function (model) {
                        return (
                            this.ShowAddBillingCommissionException &&
                            this.ProductDetails.AddedBillingException &&
                            model.Country == this.CountryUnitedStatesValue
                        );
                    }),
                    numeric,
                    maxLength: maxLength(5),
                    minLength: minLength(5),
                },
                State: {
                    required: requiredIf(function (model) {
                        return (
                            this.ShowAddBillingCommissionException &&
                            this.ProductDetails.AddedBillingException &&
                            model.Country == this.CountryUnitedStatesValue
                        );
                    }),
                },
                BillingStreetAddress: {
                    required: requiredIf(function (model) {
                        return (
                            this.ShowAddBillingCommissionException &&
                            this.ProductDetails.AddedBillingException &&
                            model.Country == this.CountryUnitedStatesValue
                        );
                    }),
                    maxLength: maxLength(26),
                },

                TruncatedBillingStreetAddress: {
                    required: requiredIf(function () {
                        return this.showtrucatedbilling == true;
                    }),
                    maxLength: maxLength(26),
                },
                SelectedMediaList: {
                    required: requiredIf(function () {
                        return (
                            this.ShowAddBillingCommissionException &&
                            this.ProductDetails.AddedBillingException &&
                            !this.ProductDetails.SelectedMediaList.includes("AXTimeOnly")
                        );
                    }),
                },
            },
        },
    },
    data() {
        return {
            ExistingMediaOrder: {
                CABLE: 0,
                NATIONAL: 1,
                SYNDICATION: 0,
                "SPOT TV": 2,
                "SPOT RADIO": 3,
                "NETWORK RADIO": 4,
                MAGAZINE: 5,
                INTERACTIVE: 6,
                NEWSPAPER: 7,
                OUTDOOR: 8,
                TRADE: 9,
                SEARCH: 10,
                SOCIAL: 11,
                VIDEO: 12,
                "PROG-BNDLD": 13,
                "PROG-UNBLD": 14,
                "DIG AUDIO": 15,
            },
            ProductSelectedMedia: [],
            NetMedia: "NET",
            PrintMedia: "PRINT",
            SpotMedia: "SPOT",
            ClientAgency: null,
            AgencyList: [],
            CountryUnitedStatesValue: "78d70193e1eb493dafcf3f261b7759ef",
            currentPage: 1,
            perPage: 10,
            sheets: [{
                name: "Products",
                data: [{
                    "Product Name": "",
                    "Product Code": "",
                }, ],
                components: [{
                    "Product Name": "test",
                    "Product Code": "test",
                }, ],
            }, ],
            ShowExcelUpload: false,
            dropzoneOptions: {
                init: function () {
                    this.on("maxfilesexceeded", function (file) {
                        this.removeFile(file);
                        alert("Maximum File Upload limit is 1!");
                    });
                }, // paramName1: filecount,
                acceptedFiles: ".xlsx",
                paramName: "file",
                //params: {'param_1':'xyz','para_2': localid },
                previewTemplate: this.template(),
                url: `${process.env.VUE_APP_API_ROOT}api/v1/test/`,
                uploadMultiple: false,
                maxFiles: 1,
                minFiles: 1,
                maxFilesize: 10,
                addRemoveLinks: false,
                parallelUploads: 1,
                createImageThumbnails: false,
                autoProcessQueue: false,
            },
            dropzoneOptionsCommission: {
                init: function () {
                    this.on("maxfilesexceeded", function (file) {
                        this.removeFile(file);
                        alert("Maximum File Upload limit is 1!");
                    });
                }, // paramName1: filecount,
                acceptedFiles: ".xlsx",
                paramName: "file",
                //params: {'param_1':'xyz','para_2': localid },
                previewTemplate: this.templateCommission(),
                url: `${process.env.VUE_APP_API_ROOT}api/v1/test/`,
                uploadMultiple: false,
                maxFiles: 1,
                minFiles: 1,
                maxFilesize: 10,
                addRemoveLinks: false,
                parallelUploads: 1,
                createImageThumbnails: false,
                autoProcessQueue: false,
            },
            showtruncated: false,
            showtruncationvalidationmessage: false,
            truncatedmaxlength: 20,
            editIcon: require("@/assets/Icons/edit.svg"),
            ModalTitle: "Add Product",
            ClientCode: null,
            Status: null,
            ClientName: null,
            ClientMDMID: null,
            AgencyFound: false,
            ProductsFromServer: [],
            ClientProductList: [],
            ClientMediaList: [],
            ClientProductMediaList: [],
            Clients: [],
            addIcon: require("@/assets/Icons/add.svg"),
            deleteIcon: require("@/assets/Icons/delete.svg"),
            closeIcon: require("@/assets/Icons/closeicon.svg"),
            ShowAddExceptionsPopover: false,
            ProductDetails: null,
            ShowAddBillingCommissionException: false,
            ShowAddProduct: false,
            ShowBillingException: false,
            ShowCommissionException: false,
            SelectedNETList: [],
            SelectedPRINTList: [],
            SelectedSPOTList: [],
            CommissionBasisOptions: [{
                    text: "NET",
                    value: "NET",
                },
                {
                    text: "GROSS",
                    value: "GROSS",
                },
            ],
            CountryList: [{
                    Name: "India",
                    Value: "IN",
                },
                {
                    Name: "United Kingdom",
                    Value: "UK",
                },
                {
                    Name: "United States",
                    Value: "USA",
                },
            ],
            States: [],
            NETList: [],
            SPOTList: [],
            PRINTList: [],
            Country: null,
            fields: [{
                    key: "Actions",
                    label: "",
                    sortable: false,
                },
                {
                    key: "ProductName",
                    label: "Product Name",
                    sortable: true,
                },
                {
                    key: "ProductCode",
                    label: "Product Code",
                    sortable: true,
                },
                {
                    key: "Media",
                    label: "Media",
                    sortable: true,
                },
                {
                    key: "ExistingMedia",
                    label: "Existing Media",
                    sortable: true,
                },
                {
                    key: "ProductBillingAddress",
                    label: "Address Exception",
                    sortable: true,
                },
                {
                    key: "CommissionInfo",
                    label: "Commission Exception",
                    sortable: true,
                },
                {
                    key: "UDEF1",
                    label: "UDEF 1",
                    sortable: true,
                },
                {
                    key: "UDEF2",
                    label: "UDEF 2",
                    sortable: true,
                },
            ],
            ShowDelete: false,
            DeleteProductTitle: null,
            DeleteIndex: -1,
            EditIndex: -1,
        };
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: 0,
        },
    },
    created() {
        this.InitiateObject();
        this.LoadClients();
        this.LoadStates();
        this.LoadCountries();
        this.LoadAgency();
        if (this.IsEdit) {
            this.LoadSubmittedProductDetails();
        }
    },
    watch: {
        // "ClientProductList":{
        //    deep:true,
        //    handler(val,prop,name){
        //     window.console.info(`changed the data is ${JSON.stringify(val)}`);
        //     window.console.info(`prop is ${JSON.stringify(prop)}`);
        //     window.console.info(`name is ${JSON.stringify(name)}`);

        //    }
        // },
        "ProductDetails.CommissionInfo.FeeOnly": function (newVal) {
            this.ResetCommissionBasis(newVal);
        },
         "ProductDetails.CommissionInfo.TimeOnly": function (newVal) {
            this.ResetFeeOnlyForTimeOnly(newVal);
        },
        "ProductDetails.CommissionInfo.CommissionBasis": function (newVal) {
            this.ResetFeeOnly(newVal);
        },
        ClientCode: function (newVal) {
            this.AssignClientName();
            if (newVal) {
                this.LoadProducts();
                this.LoadSelectedAgency();
                this.LoadClientMedia();
                if (!this.IsEdit) {
                    this.ResetTable();
                }
            }
        },
    },
    computed: {
        IsCurrentAXTimeOnly() {
            return this.ProductDetails.SelectedMediaList.includes("AXTimeOnly");
        },
        IsEdit() {
            return this.id && parseInt(this.id) > 0 ? true : false;
        },
        MediaList() {
            let list = [];
            list.push({
                label: "Net",
                value: NETMedia,
            });
            list.push({
                label: "Spot",
                value: SPOTMedia,
            });
            list.push({
                label: "Print",
                value: PRINTMedia,
            });
            return list;
        },
        BillingPartyNotTimeOnly() {
            //let timeOnlyOptions=["Time Only (Client Related Time MED)","Time Only (Internal Project Related Time ADM)"];
            //let timeOnlySelected=timeOnlyOptions.includes(this.BillingInfo.BillingParty);
            //return !(this.BillingInfo && timeOnlyOptions.includes(this.BillingInfo.BillingParty));
            return true;
        },
        TotalRows() {
            return this.ClientProductList != null ?
                parseInt(this.ClientProductList.length) :
                0;
        },
        IsPaginationRequired() {
            return (
                this.ClientProductList != null &&
                parseInt(this.TotalRows) > parseInt(this.perPage)
            );
        },
        IsFormValid() {
            return (
                this.$v.ClientCode.required &&
                this.ClientProductList.length > 0 &&
                !this.$v.$invalid
            );
        },
        IsClientCodeSelected() {
            return (
                this.ClientCode && this.ClientCode != "" && this.ClientCode.length > 0
            );
        },
        IsCountryUSA() {
            return this.ProductDetails.ProductBillingAddress.Country != null ?
                this.ProductDetails.ProductBillingAddress.Country ==
                "78d70193e1eb493dafcf3f261b7759ef" :
                null;
        },
        SelectedMedia() {
            return (
                !this.ProductDetails.AddedCommissionException ||
                this.ProductDetails.CommissionInfo.SelectedMediaList.length > 0 ||
                this.ProductSelectedMedia.length < 1 ||
                this.TimeOnlySelected()
            );
        },
    },
    methods: {
        mountedCommissionSupport() {
            this.ManuallyUploadFiles(this.ProductDetails.CommissionInfo.Files);
            this.$set(
                this.ProductDetails.CommissionInfo,
                "Files",
                this.GetFilesObject()
            );
        },
        ManuallyUploadFiles(files) {
            if (files != null && files.length > 0) {

                files.forEach((fileUploaded) => {
                    if (fileUploaded.FullName) {
                        let file = {
                            size: fileUploaded.Length,
                            name: fileUploaded.Name,
                            type: fileUploaded.Extension,
                        };
                        let url = fileUploaded.FullName;
                        this.$refs.commissionSupportDropzone.manuallyAddFile(file, url);
                    } else {
                        window.console.log(`file new ${JSON.stringify(fileUploaded.file)}`);
                        let file = fileUploaded.file;
                        this.$refs.commissionSupportDropzone.manuallyAddFile(file, "");

                    }
                });
            }
        },
        IsNotAxTimeOnly(row) {
            return (
                row.SelectedMediaList.length == 0 ||
                row.SelectedMediaList.length > 1 ||
                !row.SelectedMediaList.includes("AXTimeOnly")
            );
        },
        UploadedToDropzone() {
            window.console.log(`called uploaded`);
            this.$set(
                this.ProductDetails.CommissionInfo,
                "Files",
                this.GetFilesObject()
            );
        },
        RemovedFromDropzone() {
            this.$set(
                this.ProductDetails.CommissionInfo,
                "Files",
                this.GetFilesObject()
            );
        },
        GetFilesObject() {
            let files = [];
            window.console.log(`files ${JSON.stringify(this.$refs.commissionSupportDropzone.dropzone.files)}`);

            if (
                this.$refs.commissionSupportDropzone.dropzone.files &&
                this.$refs.commissionSupportDropzone.dropzone.files.length > 0
            ) {
                let filesExists = this.$refs.commissionSupportDropzone.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name,
                        file: filesExists[i],
                    });
                }
                return files;
            } else {
                return files;
            }
        },
        formatMediaTypesForGrid(index, row) {
            if (index > -1 && row.CommissionInfo.SelectedMediaList.length > 0) {
                let media = row.CommissionInfo.SelectedMediaList.map((med) => med).join(
                    ","
                );
                return media;
            } else {
                return "";
            }
        },
        IsRejected() {
            return (
                this.Status == "Rejected" ||
                this.Status == "Cancelled" ||
                this.Status == "Canceled"
            );
        },
        GetNonSelectedMediaList(row) {
            if (row.ExistingProduct == true) {
                let existingMedia = this.getMediaWithActualValue(row.ProductCode);
                // window.console.log('test existing');
                // window.console.log(JSON.stringify(existingMedia));

                if (existingMedia.length > 0) {
                    let list = [];
                    let netMedia = this.GetFinalMediaList(NETMedia, existingMedia);
                    if (netMedia.length > 0) {
                        list.push({
                            label: "Net",
                            value: netMedia,
                        });
                    }
                    let spotMedia = this.GetFinalMediaList(SPOTMedia, existingMedia);
                    if (spotMedia.length > 0) {
                        list.push({
                            label: "Spot",
                            value: spotMedia,
                        });
                    }
                    let printMedia = this.GetFinalMediaList(PRINTMedia, existingMedia);
                    if (printMedia.length > 0) {
                        list.push({
                            label: "Print",
                            value: printMedia,
                        });
                    }
                    return list;
                } else {
                    return this.MediaList;
                }
            } else {
                //return this.MediaList;
                return this.MediaList.concat({
                    label: "Custom",
                    value: [{
                        label: "AX/Time Only",
                        value: "AXTimeOnly",
                    }, ],
                });
            }
        },
        GetFinalMediaList(media, existingList) {
            let filtredList = media.filter((val) => {
                return (
                    !existingList.includes(val.label) &&
                    (existingList.includes("Unbundled") ?
                        val.label != "Bundled" :
                        true) &&
                    (existingList.includes("Bundled") ? val.label != "Unbundled" : true)
                );
            });
            return filtredList;
        },
        // OnMediaSelect(index, selectedValue) {
        //     window.console.log(`selected media is ${selectedValue}`);
        //     let productRow = this.ClientProductList[index];
        //     let isMediaExists = this.ClientProductMediaList.findIndex(val => {
        //         return val.key == productRow.ProductCode;
        //     })
        //     if (isMediaExists > -1) {
        //         let medias = this.ClientProductMediaList[isMediaExists].value;
        //         window.console.log(`the media list is ${JSON.stringify(medias)}`);
        //         let isExists = medias.findIndex(val => {
        //             return val.label == selectedValue;
        //         })
        //         if (isExists > -1) {
        //             alert("The media is already active");
        //         }
        //     }
        // },
        getMediaWithActualValue(productCode) {
            let list = this.formatExistingMedia(productCode);
            let correctedList = list.split(",");
            if (correctedList.length > 0) {
                correctedList = list
                    .replace("SPOT RADIO", "Radio")
                    .replace("SPOT TV", "TV")
                    .replace("NTWK RADIO", "Network Radio")
                    .replace("DIG AUDIO", "Digital Audio")
                    .replace("PROG-BNDLD", "Bundled")
                    .replace("PROG-UNBLD", "Unbundled")
                    .replace("TRADE", "Trade")
                    .replace("CABLE", "Cable")
                    .replace("INTERACTIVE", "Interactive")
                    .replace("MAGAZINE", "Magazine")
                    .replace("NATIONAL", "National")
                    .replace("NEWSPAPER", "Newspaper")
                    .replace("OUTDOOR", "Outdoor")
                    .replace("SEARCH", "Search")
                    .replace("SOCIAL", "Social")
                    .replace("MAGAZINE", "Magazine")
                    .replace("SYNDICATION", "Syndication")
                    .replace("VIDEO", "Video")
                    .replace(" ", "")
                    .split(",");
                correctedList = correctedList.map((val) => val.trim());
                //window.console.log(`corrected list is ${JSON.stringify(correctedList)}`);
                // if (correctedList.length > 0) {
                //     if (correctedList.indexOf("Unbundled") > -1 && !correctedList.indexOf('Bundled') > -1) {
                //         correctedList = correctedList.filter(corrected => {
                //             return !corrected.indexOf("Bundled") > -1;
                //         })
                //     } else if (!correctedList.indexOf("Unbundled") > -1 && correctedList.indexOf('Bundled') > -1) {
                //         correctedList = correctedList.filter(corrected => {
                //             return !corrected.indexOf("Unbundled") > -1;
                //         })
                //     }
                // }
                return correctedList;
            } else {
                return [];
            }
        },
        formatExistingMedia(productCode) {
            // window.console.log(`selected media is ${productCode}`);
            let isMediaExists = this.ClientProductMediaList.findIndex((val) => {
                return val.key == productCode;
            });
            if (isMediaExists > -1) {
                let medias = this.ClientProductMediaList[isMediaExists].value;
                // window.console.log(`the media list is ${JSON.stringify(medias)}`);
                //         let
                let list = medias
                    .map((val) => {
                        return val.label;
                    })
                    .sort(
                        (a, b) =>
                        this.ExistingMediaOrder[a.trim()] -
                        this.ExistingMediaOrder[b.trim()]
                    )
                    .join(",");
                // window.console.log(`the media format is ${JSON.stringify(list)}`);
                return list;
            } else {
                return "";
            }
        },
        AssignClientName() {
            this.ClientName = null;
            this.ClientMDMID = null;

            if (this.ClientCode != null && this.ClientCode.length > 0) {
                let clientIndex = this.Clients.findIndex((val) => {
                    return val.value == this.ClientCode;
                });
                if (clientIndex > -1) {
                    let clientObj = this.Clients[clientIndex];
                    this.ClientName = clientObj.ClientName;
                    this.ClientMDMID = clientObj.ClientMDMID;
                }
            }
        },
        DisableEditing(index, prop) {
            let filterField = prop == "ProductName" ? "label" : "value";
            let alternateField = prop == "ProductName" ? "value" : "label";

            let currentValue = this.ClientProductList[index][prop];
            let currentObject = this.ClientProductList[index];

            let isProductExists = this.ProductsFromServer.findIndex(function (val) {
                return val[filterField] == currentValue;
            });

            // window.console.log(`prop:${prop},filterField:${filterField},alternateFilterField:${alternateField},currentvalue:${currentValue},index:${isProductExists}`)
            if (isProductExists > -1) {
                currentObject.ExistingProduct = true;
                if (prop == "ProductName") {
                    currentObject.ProductCode =
                        this.ProductsFromServer[isProductExists][alternateField];
                }
                if (prop == "ProductCode") {
                    currentObject.ProductName =
                        this.ProductsFromServer[isProductExists][alternateField];
                }
                this.$set(this.ClientProductList, index, currentObject);
                //this.$v.ClientProductList[index].ProductCode.$touch();
                this.$v.ClientProductList.$each.$iter[index].ProductCode.$touch();
                this.LoadProductMedia(currentObject.ProductCode);
            }
        },
        ResetListDirty(index, prop) {
            this.$v.ClientProductList.$each.$iter[this.GetRowIndex(index)][
                prop
            ].$reset();
        },
        ResetDirty(prop) {
            this.$v[prop].$dirty = false;
        },
        ResetZipCodeDirty() {
            this.$v.ProductDetails.ProductBillingAddress.ZipCode.$reset();
        },
        ResetCommissionDirty() {
            this.$v.ProductDetails.CommissionInfo.CommissionPercentage.$reset();
        },
        ResetTable() {
            this.ClientProductList = [];
            this.AddAnotherProduct();
        },
        FindMedia(mediaList, mediaType, mediaCategory) {
            let mediaFound = mediaList.findIndex((val) => {
                return val.value == mediaType;
            });
            if (mediaFound > -1) {
                return mediaCategory;
            } else {
                return "";
            }
        },
        GetMediaCategory(mediaType) {
            let MediaCategory = this.FindMedia(NETMedia, mediaType, this.NetMedia);
            if (MediaCategory == "")
                MediaCategory = this.FindMedia(SPOTMedia, mediaType, this.SpotMedia);
            if (MediaCategory == "")
                MediaCategory = this.FindMedia(PRINTMedia, mediaType, this.PrintMedia);
            return MediaCategory;
        },
        LoadUsedMedia(mediaList, mediaSelected, selectedList) {
            mediaList.forEach((val) => {
                let indexMedia = mediaSelected.findIndex((media) => {
                    return media.MediaType == val.MediaType;
                });
                if (indexMedia > -1) {
                    selectedList.push(val.MediaType);
                }
            });
        },
        async LoadProductRequests() {},
        async LoadSelectedAgency() {
            let clientCode = this.ClientCode;
            let propertyName = "Agency";
            this.$store
                .dispatch("client/GetClientPropertyByCode", {
                    clientCode,
                    propertyName,
                })
                .then((res) => {
                    if (res.data) {
                        this.ClientAgency = res.data;
                        this.AgencyFound = true;
                    } else {
                        this.ClientAgency = null;
                        this.AgencyFound = false;
                    }
                })
                .catch((ex) => {
                    this.AgencyFound = false;
                    if (ex.response) {
                        var commenmsg = "";
                        if (ex.response.status == 500) {
                            commenmsg = "Please check your inputs or try again later";
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else if (ex.response.status == 400) {
                            for (var i = 0; i < ex.response.data.length; i++) {
                                if (ex.response.data[i].ErrorMessage != "undefined") {
                                    commenmsg += ex.response.data[i].ErrorMessage;
                                }
                            }
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else {
                            store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                        }
                    } else {
                        store.dispatch(
                            "progress/setErrorMessage",
                            `Error while fetching selected agency. ${ex}`
                        );
                    }
                });
        },
        async LoadAgency() {
            service
                .getAgencyDropdownValues("")
                .then((res) => {
                    this.AgencyList = res.data;
                })
                .catch((err) => {
                    alert(err);
                });
        },
        GetRowIndex(index) {
            return index + (this.currentPage - 1) * this.perPage;
        },
        async LoadProducts() {
            this.$store
                .dispatch("product/GetProducts", this.ClientCode)
                .then((response) => {
                    this.ProductsFromServer = response.data;
                })
                .catch((ex) => {
                    window.console.log(`Error is ${ex}`);
                });
        },
        async LoadClientMedia() {
            this.$store
                .dispatch("product/GetClientMedia", this.ClientCode)
                .then((response) => {
                    this.ClientMediaList = response.data;
                })
                .catch((ex) => {
                    window.console.log(`Error is ${ex}`);
                });
        },
        async LoadProductMedia(productCode) {
            this.$store
                .dispatch("product/GetProductMedia", productCode)
                .then((response) => {
                    this.UpdateProductMediaList(productCode, response.data);
                })
                .catch((ex) => {
                    window.console.log(`Error is ${ex}`);
                });
        },
        UpdateProductMediaList(productCode, media) {
            if (this.ClientProductMediaList.length > 0) {
                let isproductExists = this.ClientProductMediaList.findIndex((val) => {
                    return val.key == productCode;
                });
                if (!isproductExists > -1) {
                    this.ClientProductMediaList.push({
                        key: productCode,
                        value: media,
                    });
                }
            } else {
                this.ClientProductMediaList.push({
                    key: productCode,
                    value: media,
                });
            }
        },
        async LoadClients() {
            // service
            //   .getEntityRecords("Clients")
            //   .then((res) => {
            //     this.Clients = res.data;
            //     this.Clients = [
            //       { text: "Option1", value: "Optionvalue1" },
            //       { text: "Option2", value: "Optionvalue2" },
            //     ];
            //   })
            //   .catch((err) => {
            //     alert(err);
            //   });
            this.$store
                .dispatch("product/GetClients")
                .then((response) => {
                    this.Clients = response.data;
                    //   this.Clients = [
                    // { text: "Option1", value: "Optionvalue1" },
                    //     { text: "Option2", value: "Optionvalue2" },
                    // ];
                })
                .catch((ex) => {
                    window.console.log(`Error is ${ex}`);
                });
        },
        ReloadNETMedia() {
            this.NETList = [];
            if (this.ProductDetails.SelectedMediaList != null) {
                NETMedia.forEach((val) => {
                    var netIndex = this.ProductDetails.SelectedMediaList.findIndex(
                        (media) => {
                            return media == val.value;
                        }
                    );
                    if (netIndex > -1) {
                        this.NETList.push({
                            ...val,
                        });
                    }
                });
            }
        },
        GetSelectedMediaDetails() {
            this.ProductSelectedMedia = [];
            this.MediaList.forEach((media) => {
                let subSelectedMedia = {
                    label: media.label,
                    value: [],
                };
                this.ProductDetails.SelectedMediaList.forEach((mediaUsed) => {
                    let index = media.value.findIndex((subMedia) => {
                        return subMedia.value == mediaUsed;
                    });
                    if (index > -1) {
                        subSelectedMedia.value.push(media.value[index]);
                    }
                });
                if (subSelectedMedia.value.length > 0) {
                    this.ProductSelectedMedia.push(subSelectedMedia);
                }
            });
        },
        ReloadSPOTMedia() {
            this.SPOTList = [];
            if (this.ProductDetails.SelectedMediaList != null) {
                SPOTMedia.forEach((val) => {
                    var netIndex = this.ProductDetails.SelectedMediaList.findIndex(
                        (media) => {
                            return media == val.value;
                        }
                    );
                    if (netIndex > -1) {
                        this.SPOTList.push({
                            ...val,
                        });
                    }
                });
            }
        },
        ReloadPRINTMedia() {
            this.PRINTList = [];
            if (this.ProductDetails.SelectedMediaList != null) {
                PRINTMedia.forEach((val) => {
                    var netIndex = this.ProductDetails.SelectedMediaList.findIndex(
                        (media) => {
                            return media == val.value;
                        }
                    );
                    if (netIndex > -1) {
                        this.PRINTList.push({
                            ...val,
                        });
                    }
                });
            }
        },
        AddProductUploadedProduct() {
            let currentCount = this.ClientProductList.length;
            this.ClientProductList.push({
                ...this.ProductDetails,
            });
            for (var i = currentCount; i < this.ClientProductList.length; i++) {
                this.DisableEditing(i, "ProductName");
                if (this.ClientProductList[i].ExistingProduct != true) {
                    this.DisableEditing(i, "ProductCode");
                }
            }
            this.InitiateObject();
        },
        AddAnotherProduct() {
            this.ClientProductList.push({
                ...this.ProductDetails,
            });
            this.ClientProductList.forEach((value) => {
                this.$watch(
                    () => value.SelectedMediaList,
                    function (val, old) {
                        // if (val.includes('Cable') && val.includes('National') && val.includes('Syndication')) {
                        //     return val;
                        // } else if (val.includes('Cable') && !val.includes('National') && !val.includes('Syndication')) {
                        //     val.push('National');
                        //     val.push('Syndication');
                        // } else if (val.includes('National') && !val.includes('Cable') && !val.includes('Syndication')) {
                        //     val.push('Cable');
                        //     val.push('Syndication');
                        // } else if (val.includes('Syndication') && !val.includes('National') && !val.includes('Cable')) {
                        //     val.push('Cable');
                        //     val.push('National');
                        // } else if (old.includes('Cable') && old.includes('National') && old.includes('Syndication')) {
                        //     if (!val.includes('Cable') || !val.includes('National') || !val.includes('Syndication')) {
                        //         var cableindex = val.indexOf('Cable');
                        //         if (cableindex > -1) {
                        //             val.splice(cableindex, 1);
                        //         }

                        //         var nationalindex = val.indexOf('National');
                        //         if (nationalindex > -1) {
                        //             val.splice(nationalindex, 1);
                        //         }

                        //         var synindex = val.indexOf('Syndication');
                        //         if (synindex > -1) {
                        //             val.splice(synindex, 1);
                        //         }
                        //     }
                        // }
                        window.console.log(`new values is ${JSON.stringify(val)} `);
                        if (val.includes("AXTimeOnly") && val.length > 1) {
                            //val=val.filter(rel=>rel=="NONE");
                            let index5 = val.indexOf("AXTimeOnly");
                            if (index5 > -1) {
                                // val.splice(index5, 1);
                                val.splice(0, 0, val.splice(index5, 1)[0]);
                            }
                            let arraLength = val.length;
                            for (var i = 1; i <= arraLength; i++) {
                                val.splice(i, 1);
                            }
                        } else if (old.includes("Bundled") && val.includes("Unbundled")) {
                            var index5 = val.indexOf("Bundled");
                            if (index5 > -1) {
                                val.splice(index5, 1);
                            }
                        } else if (old.includes("Unbundled") && val.includes("Bundled")) {
                            var index6 = val.indexOf("Unbundled");
                            if (index6 > -1) {
                                val.splice(index6, 1);
                            }
                        } else if (val.includes("Bundled") && val.includes("Unbundled")) {
                            var index = val.indexOf("Unbundled");
                            if (index > -1) {
                                val.splice(index, 1);
                            }
                            var index1 = val.indexOf("Bundled");
                            if (index1 > -1) {
                                val.splice(index1, 1);
                            }
                        }
                    }, {
                        deep: true,
                    }
                );
            });
            this.InitiateObject();
            this.currentPage = Math.ceil(
                this.ClientProductList.length / this.perPage
            );
        },
        async downloadProductsUploadTemplate() {
            let file = {
                Name: "FinancialSystemsProductsForm.xlsx",
            };
            this.$store
                .dispatch("client/DownloadProductsUploadTemplate", file)
                .then((response) => {
                    const blob = new Blob([response.data], {
                        type: file.Extension,
                    });
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = file.Name;
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Error while downloading file. ${ex.response.data}`
                        );
                    } else {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Error while downloading file. ${ex}`
                        );
                    }
                });
        },
        DeleteProductBillingExceptionSection() {
            this.DeleteBillingExceptionSection();
        },
        DeleteProductCommissionExceptionSection() {
            this.DeleteCommissionExceptionSection();
        },
        AddProductCommissionExceptionSection() {
            this.ProductDetails.AddedCommissionException = true;
        },
        AddProductBillingExceptionSection() {
            this.ProductDetails.AddedBillingException = true;
        },
        ShowBillingCommissionException(index, commission) {
            this.ShowAddBillingCommissionException = true;
            this.EditIndex = index + (this.currentPage - 1) * this.perPage;
            this.LoadProductDetails();
            if (this.ProductDetails.Agency == null && this.ClientAgency != null) {
                this.$set(this.ProductDetails, "Agency", this.ClientAgency);
            }
            this.$v.ProductDetails.CommissionInfo.CommissionPercentage.$reset();
            if (commission == true) {
                this.$set(this.ProductDetails, "AddedCommissionException", true);
            } else {
                this.$set(this.ProductDetails, "AddedBillingException", true);
            }
        },
        CancelAddException() {
            this.ShowAddBillingCommissionException = false;
            this.InitiateObject();
            this.EditIndex = -1;
            this.DeleteCommissionExceptionSection();
            this.DeleteBillingExceptionSection();
        },
        AddProductBillingCommissionException() {
            this.SaveProduct();
            this.ShowAddBillingCommissionException = false;
            this.InitiateObject();
            this.EditIndex = -1;
        },
        AddUsedMedia() {
            this.ProductDetails.Media = [];
            this.$set(this.ProductDetails, "Media", []);
            this.SelectedNETList.forEach((element) => {
                this.ProductDetails.Media.push({
                    MediaCategory: this.NetMedia,
                    MediaType: element.toString(),
                });
            });
            this.SelectedPRINTList.forEach((element) => {
                this.ProductDetails.Media.push({
                    MediaCategory: this.PrintMedia,
                    MediaType: element.toString(),
                });
            });
            this.SelectedSPOTList.forEach((element) => {
                this.ProductDetails.Media.push({
                    MediaCategory: this.SpotMedia,
                    MediaType: element.toString(),
                });
            });
        },
        AssignDropdownNames(obj) {
            if (obj.ProductBillingAddress.Country != null) {
                let countrySelected = this.CountryList.findIndex((val) => {
                    return val.value == obj.ProductBillingAddress.Country;
                });
                if (countrySelected > -1) {
                    this.$set(
                        obj.ProductBillingAddress,
                        "CountryName",
                        this.CountryList[countrySelected].label.split("-")[1]
                    );
                }
            }
            if (obj.ProductBillingAddress.State != null) {
                let countrySelected = this.States.findIndex((val) => {
                    return val.value == obj.ProductBillingAddress.State;
                });
                if (countrySelected > -1) {
                    this.$set(
                        obj.ProductBillingAddress,
                        "StateName",
                        this.States[countrySelected].label
                    );
                }
            }
        },

        SaveProduct() {
            this.AddUsedMedia();
            this.AssignDropdownNames(this.ProductDetails);
            this.$set(this.ClientProductList, this.EditIndex, this.ProductDetails);
            this.CancelAdd();
            this.InitiateObject();
        },
        CancelAdd() {
            this.showtrucatedbilling = false;
            this.ShowAddProduct = false;
            this.ShowBillingException = false;
            this.ShowCommissionException = false;
            this.EditIndex = -1;
            this.InitiateObject();
        },
        CancelExcelUpload() {
            this.ShowExcelUpload = false;
        },
        template: function () {
            return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details" style='margin-right:79px !important;'>
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
        },
        templateCommission: function () {
            return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details" style='margin-right:30px !important;'>
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
        },

        openFileUpload() {
            this.ShowExcelUpload = true;
            // this.$refs.file.value=null;
            // this.$refs.file.click();
        },
        mapUploadedFileData(rows) {
            if (rows) {
                if (rows.length > 0) {
                    rows.forEach((item) => {
                        this.$set(this.ProductDetails, "ProductName", item["Product Name"]);
                        this.$set(this.ProductDetails, "ProductCode", item["Product Code"]);
                        this.$set(this.ProductDetails, "UDEF1", item["UDEF1"]);
                        this.$set(this.ProductDetails, "UDEF2", item["UDEF2"]);
                        this.AddProductUploadedProduct();
                    });
                    store.dispatch(
                        "progress/setSuccessMessage",
                        `${rows.length} product(s) uploaded successfully.`
                    );
                    this.$v.$touch();
                } else {
                    store.dispatch("progress/setErrorMessage", `No product(s) to upload`);
                }
            }
        },
        AddProductsFromExcel() {
            let columns = "";
            const schema = {
                "Product Name": {
                    prop: "Product Name",
                    type: String,
                },
                "Product Code": {
                    prop: "Product Code",
                    type: String,
                },
                UDEF1: {
                    prop: "UDEF1",
                    type: String,
                },
                UDEF2: {
                    prop: "UDEF2",
                    type: String,
                },
            };
            if (this.$refs.excelUploadDropzone.dropzone.files.length > 0) {
                if (
                    this.$refs.excelUploadDropzone.dropzone.files[0].name.indexOf(
                        ".xlsx"
                    ) > -1
                ) {
                    var file = this.$refs.excelUploadDropzone.dropzone.files[0];
                    // alert(`${JSON.stringify(this.$refs.file.files.length)}`);
                    readXlsxFile(file, {
                        schema,
                        transformData(data) {
                            // // Adds header row to the data.
                            // return [['Product Name', 'Product Code']].concat(data);
                            // Removes empty rows.
                            if (data != null) columns = data.toString();
                            return data.filter(
                                (row) => row.filter((column) => column !== null).length > 0
                            );
                        },
                    }).then(({
                        rows
                    }) => {
                        // `errors` have shape `{ row, column, error, value }`
                        if (
                            (columns.indexOf("Product Name") > -1 &&
                                columns.indexOf("Product Code") > 12) ||
                            (columns.indexOf("Product Name") > 12 &&
                                columns.indexOf("Product Code") > -1)
                        ) {
                            this.mapUploadedFileData(rows);
                            this.ShowExcelUpload = false;
                        } else {
                            store.dispatch(
                                "progress/setErrorMessage",
                                `File format is not valid, it should contain 'Product Name' and 'Product Code' columns.`
                            );
                        }
                    });
                } else {
                    store.dispatch(
                        "progress/setErrorMessage",
                        `Only .xlsx file types are allowed`
                    );
                }
            } else {
                store.dispatch(
                    "progress/setErrorMessage",
                    `Please select .xlsx file to upload`
                );
            }
        },
        DeleteCommissionExceptionSection() {
            this.ShowCommissionException = false;
            this.$set(this.ProductDetails.CommissionInfo, "FeeOnly", null);
            this.$set(this.ProductDetails.CommissionInfo, "TimeOnly", null);
            this.$set(
                this.ProductDetails.CommissionInfo,
                "CommissionPercentage",
                null
            );
            this.$set(this.ProductDetails.CommissionInfo, "CommissionBasis", null);
            this.$set(this.ProductDetails, "AddedCommissionException", false);
            this.$set(this.ProductDetails, "Media", []);
            this.ResetCommissionMedia();
        },
        IsMediaUsed(list) {
            return list != null && list.length > 0;
        },
        TimeOnlySelected() {
            return (
                this.BillingPartyTimeOnly ||
                this.ProductDetails.CommissionInfo.TimeOnly == "true"
            );
        },
        ResetCommissionMedia() {
            this.ProductDetails.CommissionInfo.FeeOnly = null;
            this.ProductDetails.CommissionInfo.CommissionBasis = null;
            this.ProductDetails.CommissionInfo.CommissionPercentage = null;
            this.SelectedNETList = [];
            this.SelectedSPOTList = [];
            this.SelectedPRINTList = [];
        },
        DeleteBillingExceptionSection() {
            this.ShowBillingException = false;
            this.$set(this.ProductDetails, "AddedBillingException", false);
            this.$set(this.ProductDetails.ProductBillingAddress, "Country", null);
            this.$set(this.ProductDetails.ProductBillingAddress, "CountryName", null);
            this.$set(this.ProductDetails.ProductBillingAddress, "City", null);
            this.$set(this.ProductDetails.ProductBillingAddress, "State", null);
            this.$set(this.ProductDetails.ProductBillingAddress, "StateName", null);
            this.$set(this.ProductDetails.ProductBillingAddress, "ZipCode", null);
            this.$set(
                this.ProductDetails.ProductBillingAddress,
                "AddressLine1",
                null
            );
            this.$set(
                this.ProductDetails.ProductBillingAddress,
                "AddressLine2",
                null
            );
            this.$set(
                this.ProductDetails.ProductBillingAddress,
                "BillingStreetAddress",
                null
            );
            this.$set(this.ProductDetails.ProductBillingAddress, "Attn", null);
        },
        IsMediaSelected(list) {
            let selectedMedia = -1;
            selectedMedia = list.findIndex((obj) => {
                return obj.Selected;
            });
            if (selectedMedia > -1) {
                return true;
            }
        },
        handleBlur() {
            this.showtruncationvalidationmessage = false;
        },

        remaincharCount: function () {
            if (this.ProductDetails.ProductName.length > this.truncatedmaxlength) {
                this.showtruncated = true;
                this.showtruncationvalidationmessage = true;
                this.ProductDetails.TruncatedProductName =
                    this.ProductDetails.ProductName.slice(0, 20);
            } else {
                this.showtruncated = false;
                this.showtruncationvalidationmessage = false;
                this.ProductDetails.TruncatedProductName = "";
            }
        },
        ResetCommissionBasis(value) {
            if (value) {
                this.ProductDetails.CommissionInfo.CommissionBasis = null;
            }
        },
        ResetFeeOnly(value) {
            if (value) {
                this.ProductDetails.CommissionInfo.FeeOnly = null;
            }
        },
         ResetFeeOnlyForTimeOnly(value) {
            if (value=="true"||value==true) {
                this.ProductDetails.CommissionInfo.FeeOnly = null;
            }
        },
        InitiateObject() {
            this.SelectedNETList = [];
            this.SelectedSPOTList = [];
            this.SelectedPRINTList = [];
            this.ProductDetails = {
                ExistingProduct: false,
                ProductName: null,
                ProductCode: null,
                TruncatedProductName: null,
                AddedBillingException: false,
                AddedCommissionException: false,
                Agency: null,
                ProductBillingAddress: {
                    Country: null,
                    CountryName: null,
                    City: null,
                    State: null,
                    StateName: null,
                    ZipCode: null,
                    AddressLine1: null,
                    AddressLine2: null,
                    BillingStreetAddress: null,
                    TruncatedBillingStreetAddress: null,
                    Attn: null,
                    BillToName: null,
                    SelectedMediaList: [],
                },
                CommissionInfo: {
                    FeeOnly: null,
                    CommissionBasis: null,
                    CommissionPercentage: null,
                    TimeOnly: null,
                    SelectedMediaList: [],
                    Files: [],
                },
                Media: [],
                SelectedMediaList: [],
                ProductMediaDetails: [],
                UDEF1: null,
                UDEF2: null,
            };
            this.ReloadNETMedia();
            this.ReloadSPOTMedia();
            this.ReloadPRINTMedia();
        },
        async LoadStates() {
            service
                .getEntityRecords("States")
                .then((res) => {
                    this.States = res.data;
                })
                .catch((err) => {
                    alert(err);
                });
        },

        async LoadCountries() {
            service
                .getEntityRecords("Markets")
                .then((res) => {
                    if (res.data && res.data.length > 0) {
                        this.CountryList = res.data.sort((a, b) =>
                            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                        );
                        let indexUSA = this.CountryList.findIndex(
                            (i) => i.label == "USA - United States"
                        );
                        let indexCanada = this.CountryList.findIndex(
                            (i) => i.label == "CAN - Canada"
                        );
                        if (indexCanada > -1)
                            this.CountryList.unshift(
                                this.CountryList.splice(indexCanada, 1)[0]
                            );
                        if (indexUSA > -1)
                            this.CountryList.unshift(this.CountryList.splice(indexUSA, 1)[0]);
                    }
                })
                .catch((err) => {
                    alert(err);
                });
        },
        ShowAddProductModal() {
            this.ModalTitle = "Add Product";
            this.ShowAddProduct = true;
        },
        AddSelectedMedia() {
            this.ProductDetails.Media = [];
            this.NETList.forEach((element) => {
                if (element.Selected) this.ProductDetails.Media.push(element.Name);
            });
            this.PRINTList.forEach((element) => {
                if (element.Selected) this.ProductDetails.Media.push(element.Name);
            });
            this.SPOTList.forEach((element) => {
                if (element.Selected) this.ProductDetails.Media.push(element.Name);
            });
        },
        AddProduct() {
            this.AddSelectedMedia();
            this.ClientProductList.push({
                ...this.ProductDetails,
            });
            this.CancelModal();
            this.InitiateObject();
        },
        CancelModal() {
            this.ShowAddProduct = false;
            this.ShowBillingException = false;
            this.ShowCommissionException = false;
            this.EditIndex = -1;
            this.InitiateObject();
        },
        AddBillingException() {
            this.ShowBillingException = true;
            this.ShowAddExceptionsPopover = false;
            this.ProductDetails.AddedBillingException = true;
        },
        AddCommissionException() {
            this.ShowCommissionException = true;
            this.ShowAddExceptionsPopover = false;
            this.ProductDetails.AddedCommissionException = true;
        },
        LoadProductDetails() {
            let currentProduct = this.ClientProductList[this.EditIndex];
            this.ProductDetails = JSON.parse(JSON.stringify(currentProduct));
            //this.CopyFromListToProduct(currentProduct);
            this.$set(
                this,
                "ShowCommissionException",
                currentProduct.AddedCommissionException
            );
            this.$set(
                this,
                "ShowBillingException",
                currentProduct.AddedBillingException
            );
            // this.ReloadNETMedia();
            // this.ReloadSPOTMedia();
            // this.ReloadPRINTMedia();
            this.GetSelectedMediaDetails();
            //let selectedMedia = currentProduct.Media;
            // if (selectedMedia && selectedMedia.length > 0) {
            //   this.LoadUsedMedia(NETMedia, selectedMedia, this.SelectedNETList);
            //   this.LoadUsedMedia(PRINTMedia, selectedMedia, this.SelectedPRINTList);
            //   this.LoadUsedMedia(SPOTMedia, selectedMedia, this.SelectedSPOTList);
            // }
        },
        CopyFromListToProduct(product) {

            let productDetails = {
                ID: product.ID,
                ProductName: product.ProductName,
                ProductCode: product.ProductCode,
                TruncatedProductName: null,
                AddedBillingException: product.AddedBillingException,
                AddedCommissionException: product.AddedCommissionException,
                Agency: product.Agency,
                ExistingProduct: product.ExistingProduct,
                UDEF1: product.UDEF1,
                UDEF2: product.UDEF2,
                ProductBillingAddress: product.ProductBillingAddress,
                CommissionInfo: product.CommissionInfo,
                Media: product.Media,
                SelectedMediaList: product.SelectedMediaList,
                ProductMediaDetails: product.ProductMediaDetails,
            };
            this.ProductDetails = Object.assign({}, {
                ...productDetails
            });
        },
        EditProduct(index) {
            this.EditIndex = index;
            this.ModalTitle = "Edit Product";
            this.ShowAddProduct = true;
            this.LoadProductDetails();
        },
        DeleteProduct(index) {
            this.ShowDelete = true;
            this.DeleteProductTitle = `Are you sure you want to delete ${this.ClientProductList[index].ProductName}?`;
            this.DeleteIndex = index;
        },
        DeleteConfirm() {
            this.ClientProductList.splice(this.DeleteIndex, 1);
            this.ShowDelete = false;
            this.DeleteIndex = -1;
            this.DeleteProductTitle = null;
        },
        CancelDelete() {
            this.ShowDelete = false;
            this.DeleteIndex = -1;
            this.DeleteProductTitle = null;
        },
        FormatCommissionException(data) {
            data.CommissionPercentage = parseFloat(
                data.CommissionInfo.CommissionPercentage
            );
            data.FeeOnly =
                data.CommissionInfo.FeeOnly != null ?
                data.CommissionInfo.FeeOnly == "Yes" ?
                true :
                false :
                null;
            data.TimeOnly =
                data.CommissionInfo.TimeOnly != null &&
                data.CommissionInfo.TimeOnly == "true" ?
                true :
                false;
            data.CommissionBasis = data.CommissionInfo.CommissionBasis;
            data.ProductCommissionDetails = [];
            if (
                data.CommissionInfo.SelectedMediaList != null &&
                data.CommissionInfo.SelectedMediaList.length > 0
            ) {
                data.CommissionInfo.SelectedMediaList.forEach((media) => {
                    data.ProductCommissionDetails.push({
                        MediaCategory: this.GetMediaCategory(media),
                        MediaType: media,
                    });
                });
            }
        },
        PrepareData(formData) {
            let products = [];
            this.ClientProductList.forEach((productObject) => {
                let s = {
                    ...productObject,
                };

                s.ClientID = this.ClientCode;
                if (
                    s.AddedCommissionException == true &&
                    !s.SelectedMediaList.indexOf("AXTimeOnly") > -1
                ) {
                    this.FormatCommissionException(s);
                    let files = productObject.CommissionInfo.Files;
                    for (var i = 0; i < files.length; i++) {
                        let file = files[i];

                        formData.append(
                            `files[${i}]_${productObject.ProductName}_${productObject.ProductCode}`,
                            file.file
                        );
                    }
                    s.Files = s.CommissionInfo.Files.map(function (file) {
                        return {
                            Name: file.name!=null?file.name:file.Name
                        };
                    });
                    window.console.log(`files is ${JSON.stringify(s.Files)}`);
                }
                if (s.SelectedMediaList != null && s.SelectedMediaList.length > 0) {
                    s.ProductMediaDetails = [];
                    s.SelectedMediaList.forEach((media) => {
                        s.ProductMediaDetails.push({
                            MediaCategory: this.GetMediaCategory(media),
                            MediaType: media,
                        });
                    });
                }
                //s.ProductCommissionDetails="";
                if (s.AddedBillingException != true) {
                    s.ProductBillingAddress = null;
                } else {
                    s.ProductBillingAddress.ProductBillingExceptionMediaDetails = [];
                    if (
                        s.ProductBillingAddress.SelectedMediaList != null &&
                        s.ProductBillingAddress.SelectedMediaList.length > 0
                    ) {
                        s.ProductBillingAddress.SelectedMediaList.forEach((media) => {
                            s.ProductBillingAddress.ProductBillingExceptionMediaDetails.push({
                                MediaCategory: this.GetMediaCategory(media),
                                MediaType: media,
                            });
                        });
                    }
                }
                products.push(s);
            });
            return {
                BaseProductRequestID: this.IsEdit ? parseInt(this.id) : 0,
                Status: null,
                OmniflowProcessID: null,
                OmniflowProjectID: null,
                Agency: null,
                ClientName: this.ClientName,
                Products: products,
                ClientMDMID: this.ClientMDMID,
            };
        },
        // Submit() {
        //     let data = this.PrepareData();
        //     this.$store
        //         .dispatch("product/AddProduct", data)
        //         .then(() => {
        //             this.$router.push({
        //                 name: "Client List"
        //             });
        //             store.dispatch(
        //                 "progress/setSuccessMessage",
        //                 "Your new product(s) request has been submitted!"
        //             );
        //         })
        //         .catch((ex) => {
        //             if (ex.response) {
        //                 var commenmsg = "";
        //                 if (ex.response.status == 500) {
        //                     commenmsg = "Please check your inputs or try again later";
        //                     store.dispatch("progress/setErrorMessage", `${commenmsg}`);
        //                 } else if (ex.response.status == 400) {
        //                     for (var i = 0; i < ex.response.data.length; i++) {
        //                         if (ex.response.data[i].ErrorMessage != "undefined") {
        //                             commenmsg += ex.response.data[i].ErrorMessage;
        //                         }
        //                     }
        //                     store.dispatch("progress/setErrorMessage", `${commenmsg}`);
        //                 } else {
        //                     store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
        //                 }
        //             } else {
        //                 store.dispatch(
        //                     "progress/setErrorMessage",
        //                     `Error while creating product(s). ${ex}`
        //                 );
        //             }
        //         });
        // },
        Submit() {
            let formData = new FormData();
            let data = this.PrepareData(formData);
            formData.append("productRequests", JSON.stringify(data));

            this.$store
                .dispatch("product/AddRequestWithFiles", formData)
                .then(() => {
                    this.$router.push("/ClientProduct");
                    store.dispatch(
                        "progress/setSuccessMessage",
                        "Your new product(s) request has been submitted!"
                    );
                })
                .catch((ex) => {
                    if (ex.response) {
                        var commenmsg = "";
                        if (ex.response.status == 500) {
                            commenmsg = "Please check your inputs or try again later";
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else if (ex.response.status == 400) {
                            for (var i = 0; i < ex.response.data.length; i++) {
                                if (ex.response.data[i].ErrorMessage != "undefined") {
                                    commenmsg += ex.response.data[i].ErrorMessage;
                                }
                            }
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else {
                            store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                        }
                    } else {
                        store.dispatch(
                            "progress/setErrorMessage",
                            `Error while creating product(s). ${ex}`
                        );
                    }
                });
        },
        async LoadSubmittedProductDetails() {
            this.$store
                .dispatch("product/GetProductList", parseInt(this.id))
                .then((res) => {
                    this.ClientProductList = [];
                    this.ClientCode = res.data.ClientID;
                    this.Status = res.data.Status;
                    res.data.Products.forEach((product) => {
                        this.AddProductsToList(product);
                        if (product.ExistingProduct == true) {
                            this.LoadProductMedia(product.ProductCode);
                        }
                    });
                })
                .catch((ex) => {
                    if (ex.response) {
                        var commenmsg = "";
                        if (ex.response.status == 403) {
                            this.$router.push({
                                name: "error",
                                params: {
                                    message: "UnAuthorized"
                                },
                            });
                        }
                        else if (ex.response.status == 500) {
                            commenmsg = "Please check your inputs or try again later";
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else if (ex.response.status == 400) {
                            for (var i = 0; i < ex.response.data.length; i++) {
                                if (ex.response.data[i].ErrorMessage != "undefined") {
                                    commenmsg += ex.response.data[i].ErrorMessage;
                                }
                            }
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else {
                            store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                        }
                    } else {
                        store.dispatch(
                            "progress/setErrorMessage",
                            `Error while creating client. ${ex}`
                        );
                    }
                });
        },
        AddProductsToList(product) {
            let productAddressNotNull = product.ProductBillingAddress != null;
            let productDetails = {
                ID: product.ID,
                ProductName: product.ProductName,
                ProductCode: product.ProductCode,
                TruncatedProductName: null,
                AddedBillingException: this.IsAddressException(product),
                AddedCommissionException: this.IsCommissionException(product),
                Agency: product.Agency,
                ExistingProduct: product.ExistingProduct,
                UDEF1: product.UDEF1,
                UDEF2: product.UDEF2,
                ProductBillingAddress: {
                    ID: productAddressNotNull ? product.ProductBillingAddress.ID : 0,
                    Country: productAddressNotNull ?
                        product.ProductBillingAddress.Country : null,
                    CountryName: null,
                    City: productAddressNotNull ?
                        product.ProductBillingAddress.City : null,
                    State: productAddressNotNull ?
                        product.ProductBillingAddress.State : null,
                    StateName: null,
                    ZipCode: productAddressNotNull ?
                        product.ProductBillingAddress.ZipCode : null,
                    AddressLine1: productAddressNotNull ?
                        product.ProductBillingAddress.AddressLine1 : null,
                    AddressLine2: productAddressNotNull ?
                        product.ProductBillingAddress.AddressLine2 : null,
                    BillingStreetAddress: productAddressNotNull ?
                        product.ProductBillingAddress.BillingStreetAddress : null,
                    TruncatedBillingStreetAddress: null,
                    Attn: productAddressNotNull ?
                        product.ProductBillingAddress.Attn : null,
                    BillToName: productAddressNotNull ?
                        product.ProductBillingAddress.BillToName : null,
                    SelectedMediaList: [],
                },
                CommissionInfo: {
                    FeeOnly: product.FeeOnly != null ?
                        product.FeeOnly == true ?
                        "Yes" :
                        "no" : null,
                    CommissionBasis: this.IsCommissionException(product) &&
                        product.CommissionBasisID != null ?
                        product.CommissionBasisID == 1 ?
                        "Net" :
                        "Gross" : null,
                    CommissionPercentage: product.CommissionPercentage,
                    TimeOnly: product.TimeOnly != null ?
                        product.TimeOnly == true ?
                        "true" :
                        product.TimeOnly == false ? "false" : null : null,
                    SelectedMediaList: [],
                    Files: product.Files,
                },
                Media: [],
                SelectedMediaList: [],
                ProductMediaDetails: [],
            };
            this.LoadSelectedMedia(product, productDetails);
            this.LoadAddressExceptionSelectedMedia(product, productDetails);
            this.LoadCommissionExceptionSelectedMedia(product, productDetails);
            this.ClientProductList.push(productDetails);
        },
        LoadSelectedMedia(product, productDetails) {
            if (product.ProductMediaDetails != null) {
                product.ProductMediaDetails.forEach((media) => {
                    productDetails.SelectedMediaList.push(media.MediaType);
                });
            }
        },
        LoadCommissionExceptionSelectedMedia(product, productDetails) {
            if (
                product.ProductCommissionDetails != null &&
                (product.FeeOnly != null ||
                    product.CommissionBasisID != null ||
                    product.TimeOnly == true)
            ) {
                product.ProductCommissionDetails.forEach((media) => {
                    productDetails.CommissionInfo.SelectedMediaList.push(media.MediaType);
                });
            }
        },
        LoadAddressExceptionSelectedMedia(product, productDetails) {
            if (
                product.ProductBillingAddress != null &&
                product.ProductBillingAddress.ProductBillingExceptionMediaDetails !=
                null
            ) {
                product.ProductBillingAddress.ProductBillingExceptionMediaDetails.forEach(
                    (media) => {
                        productDetails.ProductBillingAddress.SelectedMediaList.push(
                            media.MediaType
                        );
                    }
                );
            }
        },
        IsCommissionException(product) {
            return (
                product.FeeOnly != null ||
                product.CommissionBasisID != null ||
                product.TimeOnly == true
            );
        },
        IsAddressException(product) {
            return product.ProductBillingAddress != null;
        },
        ReSubmit() {
            let formData = new FormData();
            let data = this.PrepareData(formData);
            formData.append("productRequests", JSON.stringify(data));
            this.$store
                .dispatch("product/UpdateRequestWithFiles", {
                    id: parseInt(this.id),
                    data: formData,
                })
                .then(() => {
                    this.$router.push("/ClientProduct");
                    store.dispatch(
                        "progress/setSuccessMessage",
                        "Your product(s) request has been updated!"
                    );
                })
                .catch((ex) => {
                    if (ex.response) {
                        var commenmsg = "";
                        if (ex.response.status == 500) {
                            commenmsg = "Please check your inputs or try again later";
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else if (ex.response.status == 400) {
                            for (var i = 0; i < ex.response.data.length; i++) {
                                if (ex.response.data[i].ErrorMessage != "undefined") {
                                    commenmsg += ex.response.data[i].ErrorMessage;
                                }
                            }
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else {
                            store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                        }
                    } else {
                        store.dispatch(
                            "progress/setErrorMessage",
                            `Error while updating product(s). ${ex}`
                        );
                    }
                });
        },
        SaveForLater() {},
        Cancel() {
            this.$router.push("/ClientProduct");
        },
    },
};
</script>

<style scoped>
select.custom-width-dropdown {
    min-width: 180px;
}

.table td .error-message-format {
    margin-top: -5px;
    margin-bottom: 5px;
}

.table td>input {
    margin-top: 5px;
    margin-bottom: 5px;
}

.address-exception-title,
.commission-exception-title {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.57;
    letter-spacing: normal;
    color: #77a2bb;
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    padding-bottom: 13px;
}

.add-product-row {
    font-weight: bold;
    padding-left: 0px;
}

.products-upload-card * {
    font-family: "Open Sans";
    font-size: 14px !important;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.drag-and-drop-file-h {
    /* height: 50px;
  padding: 5px 90px 20px 25px;
  border-radius: 8px;
  border: solid 1px #e9eef0; */
    background-color: #ffffff;
    padding: 10px 0px 0px 10px;
}

.dropzone-custom-content,
.drag-and-drop-file-h,
.dz-message {
    height: 100% !important;
}

.draganddropspan {
    color: #00a1d2;
}

.products-upload-card section>label {
    text-transform: none;
    font-weight: 600;
}

.products-upload-card section>ol {
    margin-left: 1.5rem;
}

.customdropzone>.dz-preview dz-file-preview {
    border-top: dashed 2px #e9eef0 !important;
}

.customdropzone {
    border-radius: 8px !important;
    border: dashed 2px #e9eef0 !important;
    background-color: #ffffff !important;
    cursor: pointer !important;
    text-align: center;
    min-height: 50px;
}

.delete-exception {
    height: 10px;
    float: right;
    display: block;
}

.exception-close {
    display: block;
    padding-bottom: 14px !important;
}

.button-add-product {
    padding-bottom: 10px;
}

div.table-mandatory {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.table.b-table>thead>tr>[aria-sort="none"],
.table.b-table>tfoot>tr>[aria-sort="none"],
.table.b-table>thead>tr>[aria-sort="ascending"],
.table.b-table>tfoot>tr>[aria-sort="ascending"],
.table.b-table>thead>tr>[aria-sort="descending"],
.table.b-table>tfoot>tr>[aria-sort="descending"] {
    background-position-y: 25px !important;
    background-position-x: 95% !important;
}

.inline-custom-radio-commission {
    display: inline !important;
    margin: 0 0.25rem !important;
}

.commission-basis-with-fee .card {
    padding-left: 0px !important;
}

.ClientRectangle {
    border-radius: 5px !important;
    background-color: rgba(216, 216, 216, 0.2) !important;
    margin-left: -2% !important;
    height: 178px !important;
}

.defaultClientRectangle {
    border-radius: 5px !important;
    margin-left: 0% !important;
}

.product-items tbody td input {
    margin: 5px;
}

.truncatedvalidationmdg {
    margin-left: 13px !important;
    font-size: 10px !important;
    color: #df5678 !important;
    font-family: "Open Sans" !important;
    font-size: 11px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: italic !important;
    line-height: 0.8 !important;
    letter-spacing: normal !important;
    text-align: right !important;
}

.excel-file-upload-steps li {
    list-style: decimal;
}

.excel-file-upload-steps li a {
    color: #00a1d2;
    cursor: pointer !important;
    border: none !important;
    padding: 0px;
}

.excel-file-upload-steps li a:hover {
    color: #00a1d2;
    opacity: 0.7;
    cursor: pointer !important;
}

.products-upload-card>.card-body {
    border-radius: 5px;
}

.truncatedvalidationmdg {
    margin-left: 14px !important;
    font-size: 10px !important;
    color: #df5678 !important;
    font-family: "Open Sans" !important;
    font-size: 11px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: italic !important;
    line-height: 0.8 !important;
    letter-spacing: normal !important;
    text-align: right !important;
}

.redtextbox {
    height: 35px !important;
    border: 1px solid #df5678 !important;
    background: center right 7px no-repeat url("../../assets/Icons/error-icon.svg") #fff !important;
}

.omniipclass {
    width: 415px !important;
    height: 35px !important;
    border: #e9eef0 !important;
}

.ClientRectangle {
    border-radius: 5px !important;
    background-color: rgba(216, 216, 216, 0.2) !important;
    margin-left: -2% !important;
    height: 136px !important;
    width: 443px !important;
}

.defaultClientRectangle {
    border-radius: 5px !important;
    width: 443px !important;
    margin-left: -2% !important;
}

label[for="clientCode"] {
    text-transform: uppercase;
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    color: #4d5161;
}

.empty-text {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 0.71;
    letter-spacing: normal;
    color: #a2a9ad;
    text-transform: none;
}

.row.page-title {
    height: 55px;
    background-color: #eef3f5 !important;
}

.row.page-title>label {
    padding: 14px 0px 14px 55px !important;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.81px;
    color: #4d5161;
}

.panel-top label {
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    color: #2c3865;
}

.panel-top>label {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.57;
    letter-spacing: normal;
    color: #77a2bb;
    padding-left: 0px !important;
}

.panel-top {
    border-bottom: solid 1px rgba(151, 151, 151, 0.2);
}

span.circle-data {
    padding: 8px;
    border-radius: 50% 50%;
    border: 1px solid #018db8;
    background-color: #018db8;
    color: #fff;
    font-family: "Open Sans";
}

.exception-circle .btn {
    padding: 0.25rem 0.25rem !important;
}

.exception-title {
    text-transform: uppercase !important;
    font-family: "Open Sans";
    font-weight: 700;
    color: #00a1d2 !important;
    font-size: 10px;
}

.exception-body {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 12px;
}

.existing-media-body {
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
}

.page-title label {
    text-transform: capitalize;
}

datalist option {
    min-height: 35px;
    border: none;
    font-size: 14px;
    padding: 6px 8px;
    list-style-type: none;
}

datalist option.omg__value-selected {
    background-color: #eaebf0;
}

datalist option.omg__button-text {
    min-height: 35px !important;
    text-align: center;
    background-color: white;
    color: #00a1d2;
}

datalist option.omg__button-text .btn {
    background-color: white !important;
    border-color: white !important;
    margin-top: 0px !important;
    color: #00a1d2;
    font-size: 11px;
    min-height: 18px;
    max-height: 25px;
}

datalist option.omg__select-all {
    min-height: 23px;
    border-bottom: 1px solid transparent;
    padding: 0px;
}

datalist option.omg__select-all .btn {
    background-color: white !important;
    border-color: white !important;
    color: #00a1d2;
    margin-top: 0px !important;
    margin-bottom: 1px !important;
    font-size: 11px;
    min-height: 18px;
    max-height: 37px;
}

datalist option.omg__option * {
    margin-top: 2px;
    cursor: pointer;
}

datalist option.omg__option *.omg__value-selected {
    background-color: rgba(234, 235, 240, 0.5);
}

datalist option.omg__option-active * {
    color: #ccc;
    background-color: rgba(234, 235, 240, 0.5);
}
</style>
